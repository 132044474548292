import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import { Helmet } from "react-helmet";

function Lagnavn({ logo }) {

  console.log(logo)

  return (
    <div className="flex flex-row">
      <img className="h-6 w-6 fill-current mr-2" src={logo.value}
      >
      </img> {logo.row.values.name}
    </div>
  );
}

export default Lagnavn;
